import type { PerpsProvider } from '@kwenta/sdk/types'
import { useRouter } from 'next/router'
import { type FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSwitchChain } from 'wagmi'

import Badge from 'components/Badge'
import Button from 'components/Button'
import { Body } from 'components/Text'
import { FlexDivCol, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import { PROVIDER_MAINNET_CHAINS } from 'constants/network'
import ROUTES from 'constants/routes'

import type { DropdownItem } from '../constants'

type MobileProviderSwitcherProps = {
	items: DropdownItem[]
	onDismiss: () => void
}

const MobileProviderSwitcher: FC<MobileProviderSwitcherProps> = memo(({ items, onDismiss }) => {
	const { t } = useTranslation()
	const { switchChain } = useSwitchChain()
	const { push } = useRouter()

	const handleProviderSwitch = useCallback(
		(provider: PerpsProvider, active: boolean) => {
			if (active) {
				switchChain({ chainId: PROVIDER_MAINNET_CHAINS[provider].id })
				push(ROUTES.Markets.Home(provider))
				onDismiss()
			}
		},
		[onDismiss, push, switchChain]
	)
	return (
		<ProviderSwitchContainer>
			{!!items &&
				items.filter(Boolean).map(({ category, links }) => {
					return (
						<CategoryContainer rowGap="10px" key={category}>
							<Body size="xsmall" weight="bold" color="preview">
								{t(category)}
							</Body>
							{links.map(({ i18nLabel, icon, provider, active }) => {
								return (
									<LabelContainer
										key={i18nLabel}
										justifyContent="space-between"
										onClick={() => handleProviderSwitch(provider, active)}
										$active={active}
									>
										<FlexDivRowCentered justifyContent="flex-start" columnGap="10px">
											<StyledButton disabled={!active}>{icon}</StyledButton>
											<FlexDivCol rowGap="2px">
												<Body
													size="large"
													weight="semiBold"
													color={active ? 'primary' : 'secondary'}
												>
													{t(`${i18nLabel}-mobile`)}
												</Body>
												{!active && (
													<Badge color="gray" size="small" textTransform={false}>
														{t('header.nav.dropdown.coming-soon')}
													</Badge>
												)}
											</FlexDivCol>
										</FlexDivRowCentered>
									</LabelContainer>
								)
							})}
						</CategoryContainer>
					)
				})}
		</ProviderSwitchContainer>
	)
})

const CategoryContainer = styled(FlexDivCol)`
	width: 100%;
`

const ProviderSwitchContainer = styled(FlexDivRow)`
	margin-top: -10px;
	margin-bottom: 30px;
`

const LabelContainer = styled(FlexDivRowCentered)<{ $active: boolean }>`
	margin-top: 10px;
	svg {
		fill: none;
	}

	&:hover {
		background-color: ${(props) =>
			props.$active
				? props.theme.colors.selectedTheme.newTheme.dropdown.hover.background
				: 'initial'};
		border-radius: 8px;
		svg {
			fill: ${(props) =>
				props.$active ? props.theme.colors.selectedTheme.newTheme.dropdown.icon.fill : 'none'};
		}
	}

	border-radius: 8px;
	cursor: ${(props) => (props.$active ? 'pointer' : 'default')};
`

const StyledButton = styled(Button)`
	width: 41px;
	height: 41px;
	border-radius: 8px;
	padding: 0px;

	&:disabled {
		cursor: default;
	}
`

export default MobileProviderSwitcher
