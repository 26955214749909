import { PerpsProvider } from '@kwenta/sdk/types'
import type { ReactNode } from 'react'

import SNXIcon from 'assets/svg/partners/snx.svg'
import ArbitrumIcon from 'assets/svg/providers/arbitrum.svg'
import BaseIcon from 'assets/svg/providers/base.svg'
import OptimismIcon from 'assets/svg/providers/optimism.svg'
import { SNX_V3_ENABLED, SUPPORTED_PROVIDERS } from 'constants/futures'
import { EXTERNAL_LINKS } from 'constants/links'
import ROUTES from 'constants/routes'
import { FEEREBATE_DISABLED } from 'constants/ui'
import { HistoryTabs } from 'sections/dashboard/History/HistoryTabs'
import { MarketTabs } from 'sections/dashboard/Markets/MarketsTab'

export type Badge = {
	i18nLabel: string
	color: 'yellow' | 'red' | 'gray'
}

export type DropdownItem = {
	category: string
	links: SubMenuItem[]
}

export type SubMenuLink = {
	i18nLabel: string
	link: string
	badge?: Badge[]
	icon?: ReactNode
	externalLink?: boolean
}

export type RewardItem = {
	i18nLabel: string
	icon: ReactNode
}

export type SubMenuItem = {
	i18nLabel: string
	altLabel?: string
	icon: ReactNode
	provider: PerpsProvider
	active: boolean
	rewards?: RewardItem[]
	rewardsDisabled?: boolean
}

export type MenuLink = {
	i18nLabel: string
	link: string
	links?: SubMenuLink[] | null
	items?: DropdownItem[]
	hidden?: boolean
}

export type MenuLinks = MenuLink[]

export const HOMEPAGE_MENU_LINKS: MenuLinks = [
	{
		i18nLabel: 'homepage.nav.markets',
		link: SNX_V3_ENABLED
			? ROUTES.Markets.Home(PerpsProvider.SNX_V3_BASE)
			: ROUTES.Markets.Home(PerpsProvider.SNX_V2_OP),
	},
	{
		i18nLabel: 'homepage.nav.stats',
		link: ROUTES.Stats.Home,
	},
	{
		i18nLabel: 'homepage.nav.governance.title',
		link: ROUTES.Home.Root,
	},
	{
		i18nLabel: 'homepage.nav.blog',
		link: EXTERNAL_LINKS.Social.Mirror,
	},
]

const DASHBOARD_LINKS = [
	{
		link: ROUTES.Dashboard.Home,
		i18nLabel: 'dashboard.tabs.overview',
	},
	{
		link: ROUTES.Dashboard.History(HistoryTabs.POSITIONS),
		i18nLabel: 'dashboard.tabs.history',
	},
	{
		link: ROUTES.Dashboard.Markets(MarketTabs.ALL_MARKETS),
		i18nLabel: 'dashboard.tabs.markets',
	},
	{
		link: ROUTES.Dashboard.Leaderboard,
		i18nLabel: 'dashboard.tabs.leaderboard',
	},
]

export const getMenuLinks = (isMobile: boolean): MenuLinks => [
	{
		i18nLabel: 'header.nav.dashboard',
		link: ROUTES.Dashboard.Home,
		links: isMobile ? DASHBOARD_LINKS : null,
	},
	{
		i18nLabel: 'header.nav.markets',
		link: ROUTES.Markets.Home(
			(process.env.NEXT_PUBLIC_DEFAULT_PERPS_PROVIDER as PerpsProvider) ?? PerpsProvider.SNX_V3_BASE
		),
		items: [
			{
				category: 'header.nav.dropdown.isolated-margin',
				links: [
					{
						i18nLabel: 'header.nav.dropdown.synthetix-op',
						provider: PerpsProvider.SNX_V2_OP,
						icon: <OptimismIcon width={19} height={19} />,
						active: SUPPORTED_PROVIDERS.includes(PerpsProvider.SNX_V2_OP),
						rewards: [
							{
								i18nLabel: 'header.nav.dropdown.kwenta',
								icon: <OptimismIcon width={11} height={11} />,
							},
						],
					},
					{
						i18nLabel: 'header.nav.dropdown.perennial-arb',
						provider: PerpsProvider.PERENNIAL_V2_ARB,
						icon: <ArbitrumIcon width={19} height={19} />,
						active: SUPPORTED_PROVIDERS.includes(PerpsProvider.PERENNIAL_V2_ARB),
						rewards: [
							{
								i18nLabel: 'header.nav.dropdown.arb',
								icon: <ArbitrumIcon width={11} height={11} />,
							},
						],
					},
				],
			},
			{
				category: 'header.nav.dropdown.cross-margin',
				links: [
					{
						i18nLabel: 'header.nav.dropdown.synthetix-base',
						provider: PerpsProvider.SNX_V3_BASE,
						icon: <BaseIcon width={19} height={19} />,
						active: SUPPORTED_PROVIDERS.includes(PerpsProvider.SNX_V3_BASE),
						rewards: [
							{
								i18nLabel: 'header.nav.dropdown.snx',
								icon: <SNXIcon width={10.5} height={7} />,
							},
						],
						rewardsDisabled: FEEREBATE_DISABLED,
					},
					{
						i18nLabel: 'header.nav.dropdown.synthetix-arb',
						provider: PerpsProvider.SNX_V3_ARB,
						icon: <ArbitrumIcon width={19} height={19} />,
						active: SUPPORTED_PROVIDERS.includes(PerpsProvider.SNX_V3_ARB),
					},
				],
			},
		],
	},
	{
		i18nLabel: 'header.nav.staking',
		link: ROUTES.Staking.Home,
	},
	{
		i18nLabel: 'header.nav.rewards',
		link: ROUTES.Rewards.Home,
	},
]

export const DESKTOP_NAV_LINKS = getMenuLinks(false).filter((m) => !m.hidden)
export const MOBILE_NAV_LINKS = getMenuLinks(true).filter((m) => !m.hidden)

export const MENU_LINKS_WALLET_CONNECTED: MenuLinks = []
