import Link from 'next/link'
import { useRouter } from 'next/router'
import { type FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import MobileMenuArrow from 'assets/svg/app/mobile-menu-arrow.svg'
import FullScreenModal from 'components/FullScreenModal'
import { FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import ROUTES from 'constants/routes'
import Links from 'sections/dashboard/Links'
import Logo from 'sections/shared/Layout/Logo'

import { HOMEPAGE_MENU_LINKS, MOBILE_NAV_LINKS } from '../constants'

import MobileProviderSwitcher from './MobileProviderSwitcher'
import MobileSubMenu from './MobileSubMenu'
import { MenuButton } from './menu'

type MobileMenuModalProps = {
	onDismiss(): void
}

export const MobileMenuModal: FC<MobileMenuModalProps> = ({ onDismiss }) => {
	const { t } = useTranslation()
	const router = useRouter()
	const { asPath } = useRouter()

	const menuLinks =
		window.location.pathname === ROUTES.Home.Root ? HOMEPAGE_MENU_LINKS : MOBILE_NAV_LINKS

	const isLanding = window.location.pathname === ROUTES.Home.Root
	const isStats = asPath.split('/').includes('stats')

	const showStatsPage = useCallback(() => {
		router.push(ROUTES.Stats.Home)
		onDismiss()
	}, [router, onDismiss])

	return (
		<StyledFullScreenModal isOpen isDarkBackground={isLanding || isStats}>
			<Container>
				<LogoContainer>
					<Logo />
				</LogoContainer>
				<div>
					<MetaRow>
						<Links isMobile />
					</MetaRow>
					{menuLinks.map(({ i18nLabel, link, links, items }) => (
						<div key={link}>
							{links?.length ? (
								<MobileSubMenu
									links={links}
									i18nLabel={i18nLabel}
									defaultOpen={router.asPath.includes(link)}
									onDismiss={onDismiss}
								/>
							) : link === ROUTES.Stats.Home ? (
								<MenuButton isActive={router.asPath.includes(link)} onClick={showStatsPage}>
									{t(i18nLabel)}
									<MobileMenuArrow />
								</MenuButton>
							) : items ? (
								<>
									<MenuButton isActive={router.asPath.includes(link)} onClick={onDismiss} isLink>
										<FlexDivRowCentered>{t(i18nLabel)}</FlexDivRowCentered>
									</MenuButton>
									<MobileProviderSwitcher items={items} onDismiss={onDismiss} />
								</>
							) : (
								<Link href={link}>
									<MenuButton isActive={router.asPath.includes(link)} onClick={onDismiss} isLink>
										<FlexDivRowCentered>{t(i18nLabel)}</FlexDivRowCentered>
										<MobileMenuArrow />
									</MenuButton>
								</Link>
							)}
						</div>
					))}
				</div>
			</Container>
		</StyledFullScreenModal>
	)
}

const StyledFullScreenModal = styled(FullScreenModal)<{ isDarkBackground: boolean }>`
	top: 0;
	background-color: ${(props) =>
		props.isDarkBackground ? props.theme.colors.common.dark.darkBackground : ''};

	[data-reach-dialog-content] {
		margin: 0;
		width: 100%;
		height: 100%;

		& > div {
			height: 100%;
		}
	}
`

const Container = styled.div`
	height: 100%;
	padding: 24px 32px 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: scroll;
`

const LogoContainer = styled.div`
	margin-bottom: 50px;
`

const MetaRow = styled(FlexDivRow)`
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px;
`

export default MobileMenuModal
