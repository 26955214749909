import Link from 'next/link'
import { useRouter } from 'next/router'
import { type FC, type ReactNode, memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Badge from 'components/Badge'
import { FlexDivRow } from 'components/layout/flex'
import { GridDiv } from 'components/layout/grid'

import type { SubMenuLink } from '../constants'

import { MenuButton } from './menu'

type MobileSubMenuOption = {
	label: string
	icon?: ReactNode
	selected?: boolean
	externalLink?: string
	onClick?: () => void
}

type MobileSubMenuProps = {
	i18nLabel: string
	defaultOpen?: boolean
	options?: MobileSubMenuOption[]
	links?: SubMenuLink[]
	onDismiss(): void
}

const MobileSubMenu: FC<MobileSubMenuProps> = memo(({ i18nLabel, options, links, onDismiss }) => {
	const { t } = useTranslation()
	const { asPath } = useRouter()

	return (
		<>
			<SubMenuButton>{t(i18nLabel)}</SubMenuButton>
			<SubMenuContainer onClick={onDismiss}>
				{links
					? links.map(({ i18nLabel, link: subLink, badge, icon }) => (
							<SubMenuItemContainer key={i18nLabel}>
								<StyledLink href={subLink}>
									<SubMenuItem active={asPath.includes(subLink)}>
										<div>
											{t(i18nLabel)}{' '}
											{badge?.map(({ i18nLabel, color }) => (
												<StyledBadge key={i18nLabel} color={color}>
													{t(i18nLabel)}
												</StyledBadge>
											))}
											{icon}
										</div>
									</SubMenuItem>
								</StyledLink>
							</SubMenuItemContainer>
						))
					: options?.map(({ label, icon, onClick, selected, externalLink }) => (
							<SubMenuItemContainer key={label}>
								<SubMenuIcon selected={selected}>{icon ?? '·'}</SubMenuIcon>
								{externalLink ? (
									<SubMenuExternalLink href={externalLink} target="_blank" rel="noreferrer">
										<SubMenuItem selected={selected}>{label}</SubMenuItem>
									</SubMenuExternalLink>
								) : (
									<SubMenuFlex>
										<SubMenuItem onClick={selected ? undefined : onClick} selected={selected}>
											{label}
										</SubMenuItem>
									</SubMenuFlex>
								)}
							</SubMenuItemContainer>
						))}
			</SubMenuContainer>
		</>
	)
})

const SubMenuButton = styled(MenuButton)`
	margin-bottom: 20px;
`

const StyledBadge = styled(Badge)`
	font-size: 12px;
	padding: 2px 5px;
	margin-left: 8px;
`

const SubMenuContainer = styled(GridDiv)`
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	box-sizing: border-box;
	margin-bottom: 20px;
`

const SubMenuItemContainer = styled.div`
	border-bottom: 2px solid ${(props) => props.theme.colors.selectedTheme.newTheme.border.color};
`

const StyledLink = styled(Link)`
	flex-grow: 1;
`

const SubMenuFlex = styled.div`
	flex-grow: 1;
`

const SubMenuExternalLink = styled.a`
	flex-grow: 1;
	text-decoration: none;
`

const SubMenuItem = styled(FlexDivRow)<{ active?: boolean; selected?: boolean }>`
	font-size: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	box-sizing: border-box;
	width: 100%;
	text-transform: capitalize;
	padding-bottom: 10px;
	justify-content: flex-start;

	svg {
		height: 22px;
		width: 22px;
	}

	${(props) =>
		props.selected &&
		css`
			color: ${(props) => props.theme.colors.selectedTheme.yellow};
		`}
`

const SubMenuIcon = styled.div<{ selected?: boolean }>`
	font-weight: 700;
	font-size: 19px;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	margin-right: 20px;
	width: 12px;

	& > div {
		font-size: 12px;
	}

	.currency-icon {
		font-weight: 400;
		font-size: 19px;
		color: ${(props) => props.theme.colors.common.secondaryGray};

		${(props) =>
			props.selected &&
			css`
				color: ${(props) => props.theme.colors.common.secondaryGold};
			`}
	}
`

export default MobileSubMenu
