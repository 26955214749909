import { useCallback, useEffect, useRef } from 'react'

export default function useClickOutside(onClickOutside: () => void) {
	const ref = useRef<HTMLDivElement>(null)
	const excludeRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: any) => {
		if (
			ref.current &&
			!ref.current.contains(event.target) &&
			!excludeRef.current?.contains(event.target)
		) {
			onClickOutside()
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [])

	return { ref, excludeRef }
}
